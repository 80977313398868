<template>
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-0 pb-0 bg-primary">
      <div class="card-header border-0" style="padding: 0">
        <div class="card-title fw-bolder fs-2 mb-2 mt-5 text-inverse-info">
          {{ $t(PAGE_TITLE_PATH.ANIMAL_INFO) }}
        </div>
      </div>

      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div class="col-lg-8">
            <!--begin::Image input-->
            <div
              class="image-input image-input-outline"
              data-kt-image-input="true"
              style="background-image: url(animalModel.profilePhotoUrl)"
            >
              <!--begin::Preview existing avatar-->
              <div
                class="image-input-wrapper w-150px h-150px"
                :style="`background-image: url(${animalModel.profilePhotoUrl})`"
              ></div>
              <!--end::Preview existing avatar-->
            </div>
            <!--end::Image input-->
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a class="fs-2 fw-bolder me-1 text-inverse-info">{{
                  animalModel.name
                }}</a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  class="
                    d-flex
                    align-items-center
                    text-inverse-info text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com006.svg"
                    />
                  </span>
                  {{ animalModel.earTag }}
                </a>
                <a
                  class="
                    d-flex
                    align-items-center
                    text-inverse-info text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="/media/icons/duotune/general/gen018.svg" />
                  </span>
                  {{ animalModel.animalBreedName }}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4">
              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-sm btn-secondary"
                style="margin-right: 10px; background-color: white"
                data-bs-toggle="modal"
                data-bs-target="#vaccinate_modal"
                @click="selectAnimal()"
              >
                {{ $t("menuPages.vaccination.addVaccination") }}
              </a>
              <!--end::Button-->

              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-sm btn-secondary me-3"
                style="background-color: white"
                data-bs-toggle="modal"
                data-bs-target="#add_weight_modal"
                @click="selectAnimal()"
              >
                {{ $t("menuPages.weight.addWeight") }}
              </a>
              <!--end::Button-->

              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-sm btn-secondary me-3"
                style="background-color: white"
                data-bs-toggle="modal"
                data-bs-target="#add_disease_modal"
                @click="selectAnimal()"
              >
                {{ $t("menuPages.addDisease.pageName") }}
              </a>
              <!--end::Button-->

              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-sm btn-secondary me-3"
                style="background-color: white"
                data-bs-toggle="modal"
                data-bs-target="#feed_modal"
                @click="selectAnimal()"
              >
                {{ $t("menuPages.food.feedAnimal") }}
              </a>
              <!--end::Button-->

              <!--begin::Button-->
              <a
                href="#"
                class="btn btn-sm btn-secondary me-3"
                style="background-color: white"
                data-bs-toggle="modal"
                data-bs-target="#animal_transfer_modal"
                @click="selectAnimal()"
              >
                {{ $t("menuPages.animalTransfer.transfer") }}
              </a>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-inverse-info">Canlı Kilo</div>
                  <!--end::Label-->

                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder text-inverse-info"
                      data-kt-countup="true"
                      data-kt-countup-value="4500"
                      data-kt-countup-prefix="$"
                    >
                      {{ animalModel.liveWeight }}
                    </div>
                  </div>
                  <!--end::Number-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-inverse-info">Padok</div>
                  <!--end::Label-->

                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder text-inverse-info"
                      data-kt-countup="true"
                      data-kt-countup-value="75"
                    >
                      {{ animalModel.barnyard }}
                    </div>
                  </div>
                  <!--end::Number-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-inverse-info">Yaş</div>
                  <!--end::Label-->

                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder text-inverse-info"
                      data-kt-countup="true"
                      data-kt-countup-value="75"
                    >
                      {{ formattedAge }}
                    </div>
                  </div>
                  <!--end::Number-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            <div
              class="
                d-flex
                align-items-center
                w-200px w-sm-300px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                <span class="fw-bold fs-6 text-inverse-info">Verimlilik</span>
                <span class="fw-bolder fs-6 text-inverse-info">100%</span>
              </div>

              <div class="h-5px mx-3 w-100 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->
    </div>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useStore } from "vuex";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { useRoute } from "vue-router";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: { Field, Form, Vaccinate, AddWeight, AddDisease },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const route = useRoute();
    const animalId = route.params["id"];
const formattedAge = ref<string>("");
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const breedList = ref<BreedListModel[]>([]);

    const animalModel = ref<AnimalDetailModel>({
      id: 0,
      earTag: "",
      motherEarTag: "",
      fatherEarTag: "",
      name: "",
      animalBreedId: 1,
      barnyard: 2,
      gender: "DISI",
      acquisitionDate: new Date(),
      acquisitionPrice: 500,
      acquisitionReason: "DOGUM",
      liveWeight: 300,
      color: "",
      status: "0",
      profilePhotoUrl: "",
      birthDate: new Date(),
      carcassWeight: 0,
      documents: undefined,
      leaveDate: undefined,
      leavePrice: undefined,
      leaveReason: undefined,
    });

    watch(
      () => store.getters.newTransfer,
      () => {
        if (store.getters.newTransfer) getAnimal(Number(animalId));
      }
    );

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      getBreeds();
      getAnimal(Number(animalId));
    });

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimal = (animalId: number) => {
      const animalDetailModel: AnimalDetailModel = {
        id: animalId,
        birthDate: new Date(),
        acquisitionDate: new Date(),
      };

      animalController
        .getAnimal(animalDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalModel.value, response.getValue());
            getFormattedAge()
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getFormattedAge = () => {
    if (animalModel.value.birthDate) {
        const birthDate = animalModel.value.birthDate;
        const currentDate: Date = new Date();

        // Yaş hesabı için fark hesaplama
        const ageDiff: number = currentDate.getTime() - birthDate.getTime();

        // Farkı gün cinsine çevirme
        const ageInDays: number = ageDiff / (1000 * 60 * 60 * 24);

        // Yaşı hesapla
        if (ageInDays < 30) {
            // 30 günden kısa ise günlük olarak göster
            formattedAge.value = `${Math.floor(ageInDays)} gün`;
        } else if (ageInDays < 365) {
            // 1 aydan kısa ve 1 yıldan uzun ise aylık olarak göster
            const months: number = Math.floor(ageInDays / 30);
            const remainingDays: number = Math.floor(ageInDays % 30);
            formattedAge.value = `${months} ay ${remainingDays} gün`;
        } else {
            // 1 yıldan uzun ise yıl ve ay olarak göster
            const years: number = Math.floor(ageInDays / 365);
            const remainingMonths: number = Math.floor((ageInDays % 365) / 30);
            formattedAge.value = `${years} yıl ${remainingMonths} ay`;
        }

        return formattedAge.value;
    } else {
        return null;
    }
};



    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const selectAnimal = () => {
      store.dispatch("selectedAnimalId", animalId);
    };

    return {
      PAGE_TITLE_PATH,
      animalId,
      breedList,
      translate,
      animalModel,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      selectAnimal,
      formattedAge,
    };
  },
});
</script>
