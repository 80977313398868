
import { defineComponent, onMounted, ref, watch } from "vue";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useStore } from "vuex";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { useRoute } from "vue-router";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: { Field, Form, Vaccinate, AddWeight, AddDisease },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const route = useRoute();
    const animalId = route.params["id"];
const formattedAge = ref<string>("");
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const breedList = ref<BreedListModel[]>([]);

    const animalModel = ref<AnimalDetailModel>({
      id: 0,
      earTag: "",
      motherEarTag: "",
      fatherEarTag: "",
      name: "",
      animalBreedId: 1,
      barnyard: 2,
      gender: "DISI",
      acquisitionDate: new Date(),
      acquisitionPrice: 500,
      acquisitionReason: "DOGUM",
      liveWeight: 300,
      color: "",
      status: "0",
      profilePhotoUrl: "",
      birthDate: new Date(),
      carcassWeight: 0,
      documents: undefined,
      leaveDate: undefined,
      leavePrice: undefined,
      leaveReason: undefined,
    });

    watch(
      () => store.getters.newTransfer,
      () => {
        if (store.getters.newTransfer) getAnimal(Number(animalId));
      }
    );

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      getBreeds();
      getAnimal(Number(animalId));
    });

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimal = (animalId: number) => {
      const animalDetailModel: AnimalDetailModel = {
        id: animalId,
        birthDate: new Date(),
        acquisitionDate: new Date(),
      };

      animalController
        .getAnimal(animalDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalModel.value, response.getValue());
            getFormattedAge()
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getFormattedAge = () => {
    if (animalModel.value.birthDate) {
        const birthDate = animalModel.value.birthDate;
        const currentDate: Date = new Date();

        // Yaş hesabı için fark hesaplama
        const ageDiff: number = currentDate.getTime() - birthDate.getTime();

        // Farkı gün cinsine çevirme
        const ageInDays: number = ageDiff / (1000 * 60 * 60 * 24);

        // Yaşı hesapla
        if (ageInDays < 30) {
            // 30 günden kısa ise günlük olarak göster
            formattedAge.value = `${Math.floor(ageInDays)} gün`;
        } else if (ageInDays < 365) {
            // 1 aydan kısa ve 1 yıldan uzun ise aylık olarak göster
            const months: number = Math.floor(ageInDays / 30);
            const remainingDays: number = Math.floor(ageInDays % 30);
            formattedAge.value = `${months} ay ${remainingDays} gün`;
        } else {
            // 1 yıldan uzun ise yıl ve ay olarak göster
            const years: number = Math.floor(ageInDays / 365);
            const remainingMonths: number = Math.floor((ageInDays % 365) / 30);
            formattedAge.value = `${years} yıl ${remainingMonths} ay`;
        }

        return formattedAge.value;
    } else {
        return null;
    }
};



    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const selectAnimal = () => {
      store.dispatch("selectedAnimalId", animalId);
    };

    return {
      PAGE_TITLE_PATH,
      animalId,
      breedList,
      translate,
      animalModel,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      selectAnimal,
      formattedAge,
    };
  },
});
